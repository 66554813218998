// Getters
export const SAVING_INTERVIEW = "interview/SAVING_INTERVIEW";
export const COMMENTS = "interview/COMMENTS";
export const CUSTOMER_INFO = "interview/CUSTOMER_INFO";
export const OCCUPATIONS = "interview/OCCUPATIONS";
export const GOALS = "interview/GOALS";
export const SELECTED_GOALS = "interview/SELECTED_GOALS";
export const LIFE_INSURANCE = "interview/LIFE_INSURANCE";
export const ACQUISITIONS = "interview/ACQUISITIONS";
export const PATRIMONY = "interview/PATRIMONY";
export const HEALTH_PLAN = "interview/HEALTH_PLAN";
export const RETIREMENT = "interview/RETIREMENT";
export const MONTHLY_INVESTMENT = "interview/MONTHLY_INVESTMENT";
export const MONTHLY_INVESTMENT_CALCULATION =
  "interview/MONTHLY_INVESTMENT_CALCULATION";
export const DEBTS = "interview/DEBTS";
export const CONTRACT_SERVICE_TYPES = "interview/CONTRACT_SERVICE_TYPES";
export const PAYMENT_METHOD_TYPES = "interview/PAYMENT_METHOD_TYPES";
export const CLOSER_CONSULTANTS = "interview/CLOSER_CONSULTANTS";
export const PROPOSAL = "interview/PROPOSAL";
export const PROPOSAL_CALCULATION = "interview/PROPOSAL_CALCULATION";
export const CASH_FLOW_CATEGORIES = "interview/CASH_FLOW_CATEGORIES";
export const CASH_FLOW = "interview/CASH_FLOW";
export const CASH_FLOW_CALCULATION = "interview/CASH_FLOW_CALCULATION";
export const ADDITIONAL_INFORMATION = "interview/ADDITIONAL_INFORMATION";
export const INVESTMENTS = "interview/INVESTMENTS";
export const INTERVIEW_FORM = "interview/INTERVIEW_FORM";
export const CUSTOMER_CONTRACTS = "interview/CUSTOMER_CONTRACTS";
export const INTERVIEW_CASH_FLOW_LOADING =
  "interview/INTERVIEW_CASH_FLOW_LOADING";
export const INTERVIEW_PROPOSAL_LOADING =
  "interview/INTERVIEW_PROPOSAL_LOADING";
export const SUBSCRIPTION_PLANS = "interview/SUBSCRIPTION_PLANS";
export const CUSTOMER_SUBSCRIPTION_PLANS =
  "interview/CUSTOMER_SUBSCRIPTION_PLANS";
export const SEARCH_INTERVIEW = "interview/SEARCH_INTERVIEW";
export const LOADING_CALCULATION_PER_MONTH =
  "interview/LOADING_CALCULATION_PER_MONTH";
export const LOADING_PROPOSAL = "interview/LOADING_PROPOSAL";
export const TRANSACTION_PAYMENTS = "interview/TRANSACTION_PAYMENTS";
export const PATRIMONY_SUM = "interview/PATRIMONY_SUM";
export const LOADING_PATRIMONY = "interview/LOADING_PATRIMONY";
export const CURRENT_CURRENCY = "interview/CURRENT_CURRENCY";
export const FORMAT_CURRENCY = "interview/FORMAT_CURRENCY";
export const FORMATED_CURRENCY = "interview/FORMATED_CURRENCY";

// Mutations
export const MUTATE_CUSTOMER_INFO = "interview/MUTATE_CUSTOMER_INFO";
export const MUTATE_COMMENTS = "interview/MUTATE_COMMENTS";
export const MUTATE_SAVING_INTERVIEW = "interview/MUTATE_SAVING_INTERVIEW";
export const MUTATE_GOALS = "interview/MUTATE_GOALS";
export const MUTATE_SELECTED_GOALS = "interview/MUTATE_SELECTED_GOALS";
export const MUTATE_LIFE_INSURANCE = "interview/MUTATE_LIFE_INSURANCE";
export const MUTATE_PROFESSIONAL_INSURANCE =
  "interview/MUTATE_PROFESSIONAL_INSURANCE";
export const MUTATE_ACQUISITIONS = "interview/MUTATE_ACQUISITIONS";
export const MUTATE_OCCUPATIONS = "interview/MUTATE_OCCUPATIONS";
export const MUTATE_PATRIMONY = "interview/MUTATE_PATRIMONY";
export const MUTATE_HEALTH_PLAN = "interview/MUTATE_HEALTH_PLAN";
export const MUTATE_RETIREMENT = "interview/MUTATE_RETIREMENT";
export const MUTATE_INTEREST_IN_LIVING_ABROAD =
  "interview/MUTATE_INTEREST_IN_LIVING_ABROAD";
export const MUTATE_MONTHLY_INVESTMENT = "interview/MUTATE_MONTHLY_INVESTMENT";
export const MUTATE_MONTHLY_INVESTMENT_CALCULATION =
  "interview/MUTATE_MONTHLY_INVESTMENT_CALCULATION";
export const MUTATE_DEBTS = "interview/MUTATE_DEBTS";
export const MUTATE_CONTRACT_SERVICE_TYPES =
  "interview/MUTATE_CONTRACT_SERVICE_TYPES";
export const MUTATE_PAYMENT_METHOD_TYPES =
  "interview/MUTATE_PAYMENT_METHOD_TYPES";
export const MUTATE_CLOSER_CONSULTANTS = "interview/MUTATE_CLOSER_CONSULTANTS";
export const MUTATE_PROPOSAL = "interview/MUTATE_PROPOSAL";
export const MUTATE_PROPOSAL_CALCULATION =
  "interview/MUTATE_PROPOSAL_CALCULATION";
export const MUTATE_CASH_FLOW_CATEGORIES =
  "interview/MUTATE_CASH_FLOW_CATEGORIES";
export const MUTATE_CASH_FLOW_CALCULATION =
  "interview/MUTATE_CASH_FLOW_CALCULATION";
export const MUTATE_CASH_FLOW = "interview/MUTATE_CASH_FLOW";
export const MUTATE_ADDITIONAL_INFORMATION =
  "interview/MUTATE_ADDITIONAL_INFORMATION";
export const MUTATE_INVESTMENTS = "interview/MUTATE_INVESTMENTS";
export const MUTATE_INTERVIEW_FORM = "interview/MUTATE_INTERVIEW_FORM";
export const MUTATE_CUSTOMER_CONTRACTS = "interview/MUTATE_CUSTOMER_CONTRACTS";
export const MUTATE_INTERVIEW_CASH_FLOW_LOADING =
  "interview/MUTATE_INTERVIEW_CASH_FLOW_LOADING";
export const MUTATE_INTERVIEW_PROPOSAL_LOADING =
  "interview/MUTATE_INTERVIEW_PROPOSAL_LOADING";
export const MUTATE_SUBSCRIPTION_PLANS = "interview/MUTATE_SUBSCRIPTION_PLANS";
export const MUTATE_CUSTOMER_SUBSCRIPTION_PLANS =
  "interview/MUTATE_CUSTOMER_SUBSCRIPTION_PLANS";
export const MUTATE_CUSTOMER_ALTERED_INTERVIEW =
  "interview/MUTATE_CUSTOMER_ALTERED_INTERVIEW";
export const MUTATE_SEARCH_INTERVIEW = "interview/MUTATE_SEARCH_INTERVIEW";
export const MUTATE_SET_SEARCH_INTERVIEW =
  "interview/MUTATE_SET_SEARCH_INTERVIEW";
export const MUTATE_LOADING_CALCULATION_PER_MONTH =
  "interview/MUTATE_LOADING_CALCULATION_PER_MONTH";
export const MUTATE_LOADING_PROPOSAL = "interview/MUTATE_LOADING_PROPOSAL";
export const MUTATE_TRANSACTION_PAYMENTS =
  "interview/MUTATE_TRANSACTION_PAYMENTS";
export const MUTATE_PATRIMONY_SUM = "interview/MUTATE_PATRIMONY_SUM";
export const MUTATE_LOADING_PATRIMONY = "interview/MUTATE_LOADING_PATRIMONY";
export const MUTATE_CURRENT_CURRENCY = "interview/MUTATE_CURRENT_CURRENCY";

// Actions
export const GET_CUSTOMER_INFO = "interview/GET_CUSTOMER_INFO";
export const SAVE_SECTION_COMMENT = "interview/SAVE_SECTION_COMMENT";
export const DELETE_SECTION_COMMENT = "interview/DELETE_SECTION_COMMENT";
export const GET_SECTION_COMMENTS = "interview/GET_SECTION_COMMENTS";
export const AUTO_SAVE_CUSTOMER_INFO = "interview/AUTO_SAVE_CUSTOMER_INFO";
export const GENERATE_CONTRACT = "interview/GENERATE_CONTRACT";
export const DELETE_CUSTOMER_CHILD = "interview/DELETE_CUSTOMER_CHILD";
export const DELETE_CUSTOMER_PET = "interview/DELETE_CUSTOMER_PET";
export const SAVE_INTERVIEW = "interview/SAVE_INTERVIEW";
export const GET_GOALS = "interview/GET_GOALS";
export const GET_OCCUPATIONS = "interview/GET_OCCUPATIONS";
export const GET_SELECTED_GOALS = "interview/GET_SELECTED_GOALS";
export const AUTO_SAVE_SELECTED_GOALS = "interview/AUTO_SAVE_SELECTED_GOALS";
export const SAVE_SELECTED_GOALS = "interview/SAVE_SELECTED_GOALS";
export const DELETE_SELECTED_GOAL = "interview/DELETE_SELECTED_GOAL";
export const GET_LIFE_INSURANCE = "interview/GET_LIFE_INSURANCE";
export const AUTO_SAVE_LIFE_INSURANCE = "interview/AUTO_SAVE_LIFE_INSURANCE";
export const GET_PROFESSIONAL_INSURANCE =
  "interview/GET_PROFESSIONAL_INSURANCE";
export const AUTO_SAVE_PROFESSIONAL_INSURANCE =
  "interview/AUTO_SAVE_PROFESSIONAL_INSURANCE";
export const GET_ACQUISITIONS = "interview/GET_ACQUISITIONS";
export const AUTO_SAVE_ACQUISITIONS = "interview/AUTO_SAVE_ACQUISITIONS";
export const GET_PATRIMONY = "interview/GET_PATRIMONY";
export const AUTO_SAVE_PATRIMONY = "interview/AUTO_SAVE_PATRIMONY";
export const GET_HEALTH_PLAN = "interview/GET_HEALTH_PLAN";
export const AUTO_SAVE_HEALTH_PLAN = "interview/AUTO_SAVE_HEALTH_PLAN";
export const GET_RETIREMENT = "interview/GET_RETIREMENT";
export const AUTO_SAVE_RETIREMENT = "interview/AUTO_SAVE_RETIREMENT";
export const GET_INTEREST_IN_LIVING_ABROAD =
  "interview/GET_INTEREST_IN_LIVING_ABROAD";
export const AUTO_SAVE_INTEREST_IN_LIVING_ABROAD =
  "interview/AUTO_SAVE_INTEREST_IN_LIVING_ABROAD";
export const DELETE_INTEREST_IN_LIVING_ABROAD =
  "interview/DELETE_INTEREST_IN_LIVING_ABROAD";
export const GET_MONTHLY_INVESTMENT = "interview/GET_MONTHLY_INVESTMENT";
export const AUTO_SAVE_MONTHLY_INVESTMENT =
  "interview/AUTO_SAVE_MONTHLY_INVESTMENT";
export const GET_MONTHLY_INVESTMENT_TAXES =
  "interview/GET_MONTHLY_INVESTMENT_TAXES";
export const CALCULATE_MONTHLY_INVESTMENT =
  "interview/CALCULATE_MONTHLY_INVESTMENT";
export const GET_DEBTS = "interview/GET_DEBTS";
export const AUTO_SAVE_DEBTS = "interview/AUTO_SAVE_DEBTS";
export const GET_CONTRACT_SERVICE_TYPES =
  "interview/GET_CONTRACT_SERVICE_TYPES";
export const GET_PAYMENT_METHOD_TYPES = "interview/GET_PAYMENT_METHOD_TYPES";
export const GET_CLOSER_CONSULTANTS = "interview/GET_CLOSER_CONSULTANTS";
export const GET_PROPOSAL = "interview/GET_PROPOSAL";
export const CALCULATE_PROPOSAL = "interview/CALCULATE_PROPOSAL";
export const AUTO_SAVE_PROPOSAL = "interview/AUTO_SAVE_PROPOSAL";
export const AUTO_SAVE_RECOMMENDATION = "interview/AUTO_SAVE_RECOMMENDATION";
export const GET_CASH_FLOW_CATEGORIES = "interview/GET_CASH_FLOW_CATEGORIES";
export const GET_CASH_FLOW = "interview/GET_CASH_FLOW";
export const AUTO_SAVE_CASH_FLOW = "interview/AUTO_SAVE_CASH_FLOW";
export const CALCULATE_CASH_FLOW = "interview/CALCULATE_CASH_FLOW";
export const GET_ADDITIONAL_INFORMATION =
  "interview/GET_ADDITIONAL_INFORMATION";
export const AUTO_SAVE_ADDITIONAL_INFORMATION =
  "interview/AUTO_SAVE_ADDITIONAL_INFORMATION";
export const GET_INVESTMENTS = "interview/GET_INVESTMENTS";
export const AUTO_SAVE_INVESTMENTS = "interview/AUTO_SAVE_INVESTMENTS";
export const STORE_UPLOAD_FILE = "interview/STORE_UPLOAD_FILE";
export const GET_CUSTOMER_CONTRACTS = "interview/GET_CUSTOMER_CONTRACTS";
export const GET_SUBSCRIPTION_PLANS = "interview/GET_SUBSCRIPTION_PLANS";
export const GET_CUSTOMER_SUBSCRIPTION_PLANS =
  "interview/GET_CUSTOMER_SUBSCRIPTION_PLANS";
export const SET_CUSTOMER_FINISH_INTERVIEW =
  "interview/SET_CUSTOMER_FINISH_INTERVIEW";
export const GET_SEARCH_INTERVIEW = "interview/GET_SEARCH_INTERVIEW";
export const SET_SEARCH_INTERVIEW = "interview/SET_SEARCH_INTERVIEW";
export const DELETE_RECOMMENDATION = "interview/DELETE_RECOMMENDATION";
export const CHECK_DUPLICATE_CELL_PHONE_CLIENT =
  "interview/CHECK_DUPLICATE_CELL_PHONE_CLIENT";
export const CHECK_DUPLICATE_CELL_PHONE_PN =
  "interview/CHECK_DUPLICATE_CELL_PHONE_PN";
export const SEND_COMMERCIAL_PROPOSAL_EMAIL =
  "interview/SEND_COMMERCIAL_PROPOSAL_EMAIL";
export const GET_COMMERCIAL_PROPOSAL_LINK =
  "interview/GET_COMMERCIAL_PROPOSAL_LINK";
export const GET_TRANSACTION_PAYMENTS = "interview/GET_TRANSACTION_PAYMENTS";
export const AUTO_SAVE_TRANSACTION_PAYMENTS =
  "interview/AUTO_SAVE_TRANSACTION_PAYMENTS";
export const DELETE_TRANSACTION_PAYMENT =
  "interview/DELETE_TRANSACTION_PAYMENT";
export const FETCH_PATRIMONY_SUM = "interview/FETCH_PATRIMONY_SUM";
export const SAVE_CURRENT_CURRENCY = "interview/SAVE_CURRENT_CURRENCY";
export const FETCH_ALL_GET_REQUESTS = "interview/FETCH_ALL_GET_REQUESTS";
export const GET_CUSTOMER_FINANCIAL_DIAGNOSIS_TOKEN = "interview/GET_CUSTOMER_FINANCIAL_DIAGNOSIS_TOKEN";
