<template>
  <button
    :disabled="isLoading || loading"
    class="btn btn-outline-light mx-2 mt-1"
    @click.prevent="saveAndPerformNextStep(openDiagnosisLink)"
  >
    {{
      isLoading || loading
        ? "Por favor, aguarde um momento..."
        : "Abrir diagnóstico financeiro"
    }}
  </button>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minValue, maxValue } from "@vuelidate/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import * as types from "../store/types";
import Spinner from "@/modules/shared/components/Spinner";
import ConfirmModal from "@/modules/shared/components/ConfirmModal";
import { processFormErrors } from "@/helpers/interview_payloads";
import { processErrorMessages } from "@/helpers/errors";

export default {
  components: {
    Spinner,
    ConfirmModal,
  },
  data() {
    return {
      loading: false,
      diagnosisDays: 7,
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const diagnosisUrl =
      process.env.VUE_APP_FINANCIAL_DIAGNOSIS_FRONTEND_URL + "/autenticacao/";
    return { diagnosisUrl, v$: useVuelidate() };
  },
  validations() {
    return {
      diagnosisDays: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(7),
      },
    };
  },
  computed: {
    ...mapGetters({
      customerInfo: types.CUSTOMER_INFO,
      form: types.INTERVIEW_FORM,
    }),
  },
  methods: {
    ...mapActions({
      getFinancialDiagnosisLink: types.GET_CUSTOMER_FINANCIAL_DIAGNOSIS_TOKEN,
      saveInterview: types.SAVE_INTERVIEW,
    }),
    ...mapMutations({
      savingInterviewState: types.MUTATE_SAVING_INTERVIEW,
    }),
    async saveAndPerformNextStep(nextStep) {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      const errors = await processFormErrors(this.form);
      if (errors.length > 0) {
        const messages = [
          "Os dados da entrevista não foram atualizados.",
          "Você deve solucionar as inconsistências da entrevista apontadas nas seguintes seções:",
          ...errors,
        ];
        this.$vfm.show("message", {
          type: "error",
          messages,
        });
      } else {
        this.savingInterviewState(true);
        this.saveInterview()
          .then((response) => {
            nextStep();
          })
          .catch((error) => {
            const errorsFromPayload = processErrorMessages(
              error?.response?.data
            );
            const messages = [
              "Não foi possível salvar os dados da entrevista.",
              ...errorsFromPayload,
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ];
            this.$vfm.show("message", {
              type: "error",
              messages,
            });
          })
          .finally(() => {
            this.savingInterviewState(false);
          });
      }
    },
    openDiagnosisLink() {
      this.loading = true;
      this.getFinancialDiagnosisLink({
        customer_token: this.customerInfo.token,
        days: this.diagnosisDays,
      })
        .then((response) => {
          if (response.data?.authentication_token) {
            const link =
              this.diagnosisUrl +
              response.data.authentication_token +
              "?back-url=diagnosis/" +
              this.customerInfo.token;
            setTimeout(() => {
              window.open(link, "_blank").focus();
            });
          }
        })
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Ocorreu um erro ao gerar o diagnostico. Entre em contato com o setor de TI.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
